import { Box } from '@mui/material';
import React from 'react';

// eslint-disable-next-line react/prop-types
const TableOfContents = ({ html }) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const htmlObject = { __html: html };
  return (
    <Box
      mr={2}
      ml={1}
      my={4}
      sx={{
        // 見出しのスタイルを定義
        '& ul': {
          listStyle: 'none',
          ml: 2,
        },
        '& li': { lineHeight: 0 },
        '& a': {
          textDecoration: 'none',
          lineHeight: 0.8,
        },
        '& a:hover': {
          textDecoration: 'underline',
        },
        '& p': {
          m: 0,
          lineHeight: 0,
        },
      }}
    >
      <div dangerouslySetInnerHTML={htmlObject} />
    </Box>
  );
};
export default TableOfContents;
