import { Box } from '@mui/material';
import React from 'react';

// eslint-disable-next-line react/prop-types
const Contents = ({ html }) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const htmlObject = { __html: html };
  return (
    <Box
      mx={6}
      sx={{
        '& li': {
          ml: '24px',
        },
        '& ul': {
          ml: '0px',
        },
      }}
    >
      <section dangerouslySetInnerHTML={htmlObject} />
    </Box>
  );
};
export default Contents;
